import React from 'react';



const Main = props => {
  const { children } = props;
  return (
      <h1  id="contact" className="text-3xl xs:text-3xl sm:text-4xl md:text-5xl m-0 font-bold mb-20">
        { children }
      </h1>
  );
}

const Sub = props => {
  const { children } = props;
  return (
    <h2 className="md:text-lg mb-6 font-bold border-tracking-wide font-medium uppercasew">
      { children }
    </h2>
  );
}

const Top = props => {
  const { children, dark, ...other } = props;
  return (
      <div {...other}>
          <p className="font-normal md:text-lg">
              { children }
          </p>
          <div className={`w-8 bg-brand my-4 rounded`} style={{height: 4}}/>
      </div>
  );
}

const variants = {
  main: Main,
  sub: Sub,
  top: Top,
}

const Heading = props => {
  const { variant="main", ...other } = props;
  const Wrapper = variants[variant];

  return (
    <Wrapper {...other}>
      { props.children }
    </Wrapper>
  );
}

export default Heading;
