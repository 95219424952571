import React from 'react';
import NavBar from '../components/NavBar';
import { Container } from './Grid';

const Hero = props => {
  return (
    <div className="flex min-h-screen flex-col content-between" style={{height: 500}}>
        <NavBar fixed isHomepage/>
        <div className="flex flex-1 flex-col justify-center" style={{marginTop:100}}>
            <Container>
                { props.children }
            </Container>
        </div>
    </div>
  );
}

export default Hero;
