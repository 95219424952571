import React, { useEffect } from "react";
import Heading from '../components/Heading';
import BlogPost from '../components/Blog/BlogPost';
import Hero from '../components/Hero';
import {Link, scroller} from "react-scroll";
import Reveal from '../components/Reveal';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import SEO from '../components/SEO';
import { navigate } from "gatsby";

import {Container, Grid, Item} from '../components/Grid';


// <Reveal delay={750}>
//     <Link to="" className="inline-block py-1 px-4 border border-default border-t-1 rounded-full font-bold">
//         My Resume ▸
//     </Link>
// </Reveal>

const skillData = [
    {
        name: "Python",
        level: "Intermediate",
        percent: 75
    }, {
        name: "JavaScript",
        level: "Intermediate",
        percent: 70
    }, {
        name: "Java",
        level: "Intermediate",
        percent: 70
    }, {
        name: "Objective-C",
        level: "Intermediate",
        percent: 70
    }, {
        name: "Django",
        level: "Intermediate",
        percent: 75
    }, {
        name: "React",
        level: "Intermediate",
        percent: 70
    }, {
        name: "Express",
        level: "Intermediate",
        percent: 70
    }, {
        name: "HTML and CSS",
        level: "Intermediate",
        percent: 75
    }, {
        name: "SQL",
        level: "",
        percent: 0
    }, {
        name: "Git",
        level: "",
        percent: 0
    }
];

export default({data, location}) => {
    const blogPosts = data.allMdx.edges;


    useEffect(() => {
        // If user used link in another page to index sections,
        // we have to scroll the page down to chosen anchor

        if (location.state) {
            const initialScroll = anchor => {
                const options = {
                    offset: -100,
                    duration: 2000,
                    delay: 250,
                    smooth: true,
                }
                scroller.scrollTo(anchor, options);
            }

            navigate('/', {
                state: {
                    anchor: null
                }
            });

            const anchor = location.state.anchor;
            anchor && initialScroll(anchor);

        }
    }, []);


    return (
    <div className="text-default bg-dark-dots">
        <SEO/>
        <section className="relative pb-20">
                <Hero>
                    <div className="mb-20">
                        <Reveal delay={750}>
                            <Heading variant="top" className="mb-16 md:mb-20">
                                    Hello, I'm Kamil.
                            </Heading>
                        </Reveal>
                        <Reveal duration={750} delay={1000}>
                            <h1 className="font-bold text-2xl xs:text-3xl sm:text-5xl lg:text-6xl">
                                I develop websites
                                <br/>
                                and I know things
                            </h1>
                        </Reveal>
                    </div>
                    <div className="text-sm">
                        <Reveal delay={500}>
                            <Link className="py-1 px-4 cursor-pointer block mb-4 bg-brand rounded-full font-bold" offset={-50} to="contact" smooth={true}>
                                Get in touch ▸
                            </Link>
                        </Reveal>
                    </div>
                </Hero>
        </section>

        <section id="about" className="py-20 md:min-h-screen flex items-center" style={{zIndex: -1}}>
            <Container>
                <Heading variant="top">
                        About me
                </Heading>
                <Grid>
                    <Item md="1/2">
                        <Reveal duration={750}>
                            <h1 className="text-3xl sm:text-4xl lg:text-5xl max-w-full lg:max-w-sm  m-0 font-bold max-w-sm">
                                Curious about the World
                            </h1>
                        </Reveal>
                    </Item>
                    <Item md="1/2">
                        <Reveal delay={500}>
                            <div className="text-paragraph">
                                <p className=" mt-2 mb-4 max-w-md">
                                    If I had to describe myself with one boring statement I would probably go with “open minded problem solver”… but I don’t
                                    <span aria-label="That's right" role="img"> ✌🏻</span>.
                                </p>
                                <p className=" mb-4 max-w-md">
                                    I’m truly passionate about software development and thoroughly enjoy writing readable, idiomatic code that not only does its job - it does it correctly.
                                </p>
                                <p className=" mb-4 max-w-md">
                                    Most of my time is spent crafting custom web solutions, but it doesn’t stop me from regularly exploring new ways to solve problems, no matter how hard or easy they are.
                                </p>
                            </div>
                        </Reveal>
                    </Item>
                </Grid>
            </Container>
        </section>
        <section id="skills" className="pt-20 pb-40 flex items-center">
            <Container>
                <Heading variant="top">
                    Skills
                </Heading>
                <Reveal duration={1000} delay={150} className="mb-20">
                    <h1 className="text-3xl sm:text-4xl lg:text-5xl m-0 font-bold">
                        Things I've worked with
                    </h1>
                </Reveal>
                <Reveal className="mb-4">
                    <h2 className="text-xl font-bold">Technical skills</h2>
                </Reveal>
                <div className="max-w-xl">
                    <Zoom cascade>
                        {skillData && skillData.map((el, i) => (<p key={i} className="inline-block mr-4 mb-4">{el.name}</p>))}
                    </Zoom>
                </div>
                <Grid className="mt-8">
                    <Item sm="1/2">
                        <Reveal className="mb-4">
                            <h2 className="text-xl font-bold">Education</h2>
                        </Reveal>
                        <Fade right>
                            <p>Bachelor of Engineering</p>
                            <p className="mb-4 text-paragraph">UITM in Rzeszów</p>
                            <p className="">Information Technology</p>
                            <p className="text-paragraph mb-4">Mobile and Network Technologies</p>
                        </Fade>
                    </Item>
                    <Item sm="1/2">
                        <Reveal duration={1000} className="mb-4">
                            <h2 className="text-xl font-bold">Spoken languages</h2>
                        </Reveal>
                        <Fade right>
                            <p className="mb-4">English<span className="ml-4 text-paragraph">Advanced</span>
                            </p>
                            <p className="mb-4">Polish
                                <span className="ml-4 text-paragraph">Native</span>
                            </p>
                        </Fade>
                    </Item>
                </Grid>
            </Container>
        </section>

        <section className="bg-default bg-default-dots text-dark py-40 min-h-screen flex items-center relative" style={{transition: `1s all`}}>
            <Container>
                <Heading variant="top" dark>
                    My Blog
                </Heading>
                <Reveal duration={1000} delay={250} bgClass="bg-default">
                    <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold max-w-lg">
                        Coding, rambles and exploration
                    </h1>
                </Reveal>
                <Grid className="mt-8 md:mt-20">
                    {
                        blogPosts && blogPosts.map((el, i) => {
                            return (
                            <Item key={i} xs="1/2" sm="1/2" md="1/2" lg="1/4">
                                <BlogPost animated={"true"} {...el.node.frontmatter}/>
                            </Item>);
                        })
                    }
                </Grid>
            </Container>
        </section>
        <section className="bg-body bg-dark-dots min-h-screen py-20 flex bg-contact items-center">
            <Container>
                <Heading variant="top">
                    Ready to talk?
                </Heading>
                <Reveal duration={1500} calc="calc">
                    <h1  id="contact" className="text-2xl xs:text-3xl sm:text-4xl md:text-5xl m-0 font-bold mb-20">
                        Schedule a call, leave a message or just say "Hello"
                    </h1>
                </Reveal>
                <div className="text-md sm:text-lg md:text-xl lg:text-2xl">
                    <div className="block">
                        <Zoom cascade duration={750} delay={750}>
                            <span className="font-bold text-brand mr-4">E</span>
                            <p className="inline"> hello@kamilbiel.com </p>
                        </Zoom>
                    </div>
                    <div className="block">
                        <Zoom cascade duration={750} delay={750}>
                            <span className="font-bold text-brand mr-4">P</span>
                            <p className="inline"> +48 728 376 260 </p>
                        </Zoom>
                    </div>
                </div>
            </Container>
        </section>
    </div>)
}

export const query = graphql `
query {
  allMdx(sort: {fields: [frontmatter___date], order: DESC}, limit: 4) {
    edges {
      node {
        frontmatter {
          title
          path
          tags
          date(formatString: "MMMM DD, YYYY")
          path
          image {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
        }
      }
    }
  }
}
`
